import {FETCH_INTRO_DATA, UPDATE_INTRO_DATA, UPDATE_INTRO_ERROR} from '../../state/action-types';
import { API_URL} from '../../common';
import { IIntro } from '../../interfaces';
import {IReducerAction} from '../../state/IReducerAction';
import axios from 'axios';



export function fetchIntro() {           
    return (dispatch: (arg0: IReducerAction) => any) => {         
        axios.get(`${API_URL}/Intro`)
        .then (res =>{            
            dispatch({
                type: FETCH_INTRO_DATA,
                payload: res.data
            })       
        })             
    
    };
};

export function updateIntro(data: IIntro){    
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.put(`${API_URL}/Intro/${data.id}`, data)
        .then (res =>
            dispatch({
                type: UPDATE_INTRO_DATA,
                payload: [data]
            })
        )
        .catch(error => {            
            dispatch({type: UPDATE_INTRO_ERROR, payload: error})
        }) 
    };
};

export function insertIntro(data: IIntro){    
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.post(`${API_URL}/Intro`, data)
        .then (res =>
            dispatch({
                type: UPDATE_INTRO_DATA,
                payload: [data]
            })
        )
        .catch(error => {            
            dispatch({type: UPDATE_INTRO_ERROR, payload: error})
        }) 
    };
};





