import * as React from 'react';
import ResizableTextarea from '../ResizableTextarea';
import './style.scss';
import { fetchIntro, updateIntro, insertIntro } from '../../Api/Intro'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state'
import {ITabContent, IIntro } from '../../interfaces';
import ReactMarkdown from 'react-markdown';
import TabLayout from '../Layout/EditPreview';

interface IProps {
    preview: boolean;
}



export default function IntroForm(props: IProps) {
    const
        [data, setData] = React.useState<IIntro[]>([{ id: "", heading: "", lead: "", content: "" }]),
        dispatch = useDispatch(),
        //selector = useSelector((state: IntroState) => state);
        // [{intro: intro, error: error }] = useSelector(({introReducer}:IAppState) => [introReducer]);
        // [{data: intro, error: error }] = useSelector((state: IntroState) => [state]);
        //contents:{intros:IIntro[], error: string} = useSelector(({intro}: IntroState) => intro);
        contents: { intros: IIntro[], error?: string } = useSelector(({ introReducer }: IAppState) => introReducer);



    React.useEffect(() => {
        dispatch(fetchIntro());
    }, [dispatch])

    React.useEffect(() => {
        contents.intros.length > 0 ? setData(contents.intros) : setData([{ id: "", heading: "", lead: "", content: "" }]);
    }, [contents.intros])



    const handleSubmission = async () => {
        data.forEach(d => {
            dispatch(updateIntro(d))
        });
    },
    handleInsert =() =>{
        data.forEach(d =>{ dispatch(insertIntro(d))})
    };


    const updateData = (intro: IIntro) => {
        let newData: IIntro[] = [];
        data.forEach(d => {
            d.id === intro.id ? newData.push(intro) : newData.push(d)
        })

        setData(newData);
    };

    const getPreview = () => {
        let content: any = [];
        let index = 0;
        data.forEach(d => {
            content.push(
                <React.Fragment key={index}>
                    <ReactMarkdown source={d.heading} />
                    <span className="lead"><ReactMarkdown source={d.lead} /></span>
                    <ReactMarkdown source={d.content} />
                </React.Fragment>
            )
            index++;
        });
        return content;
    };

    const getTabContent = () => {
        let tabs: ITabContent[] = [];
        tabs.push({
            content: props.preview ? getPreview() : getFormElements(),
            tabName: props.preview ? "Preview" : "Edit"
        });
        tabs.push({
            content: props.preview ? getFormElements() : getPreview(),
            tabName: props.preview ? "Edit" : "Preview"
        })
        return tabs;
    }

    const getFormElements = () => {
        let formElements: any = [];
        data.forEach(d => {
            formElements.push(
                <section className="py-2" key={d.id}>
                    <div className="container">
                        <form>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <input type="text" className="form-control" value={d.heading || ""} onChange={e => updateData({ id: d.id, heading: e.target.value, lead: d.lead, content: d.content })} required
                                        placeholder="Heading" />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <input type="text" className="form-control" value={d.lead || ""} onChange={e => updateData({ id: d.id, heading: d.heading, lead: e.target.value, content: d.content })}
                                        placeholder="Subtitle" />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg ">
                                    <ResizableTextarea className="std-textarea intro-area" placeHolder="Message" value={d.content} onUpdate={e => updateData({ id: d.id, heading: d.heading, lead: d.lead, content: e.currentTarget.value })} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <button className="btn btn-block btn-lg btn-primary" onClick={e => { e.preventDefault();d.id==="" ? handleInsert() : handleSubmission() }}>Save Changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            )
        });
        return formElements;
    };

    return (
        <div className="container">
            <TabLayout content={getTabContent()} />
        </div>
    )
};