import * as React from 'react';
import './style.scss';
import { insertImages, updateImages } from '../../Api/Images'
import { fetchIntro } from '../../Api/Intro'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state';
import TabLayout from '../Layout/EditPreview';
import ReactMarkdown from 'react-markdown';
import { ITabContent, IImages, IIntro, PageTypes } from '../../interfaces';
import PageContents from '../PageContents';
import ArticleContent from '../Article';



interface IProps {
    imageId?: string;
}


export default function ImagesForm(props: IProps) {
    const
        [data, setData] = React.useState<IImages>({ id: "", logo: "", header: "", footer: "", headerText: "", active: false }),
        dispatch = useDispatch(),
        contents: { intros: IIntro[], error?: string } = useSelector(({ introReducer }: IAppState) => introReducer),
        imageData: { images?: IImages, error?: string } = useSelector(({ imagesReducer }: IAppState) => imagesReducer),
        headerBackground = { backgroundImage: `url(${data.header})` },
        footerBackground = { backgroundImage: `url(${data.footer})` };

    function Image(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>) {
        return <img {...props} style={{ margin: '10px' }} alt=""/>
    };


    React.useEffect(() => {
        dispatch(fetchIntro());        
    }, [dispatch])

    React.useEffect(() => {
        imageData.images ? setData(imageData.images) : setData({ id: "", logo: "", header: "", footer: "", headerText: "", active: false });
    }, [imageData.images])

    const handleSubmission = async () => {
        //console.log(JSON.stringify(data));
        dispatch(updateImages(data));
    };

    const handleInsert = async () => {
        dispatch(insertImages(data));
    }

    const resetForm = () => {
        imageData.images && setData(imageData.images)
    }

    const getIntro = () => {
        let content: any = [];
        let index = 0;
        contents.intros.forEach(d => {
            content.push(
                <React.Fragment key={index}>
                    <ReactMarkdown source={d.heading} />
                    <span className="lead"><ReactMarkdown source={d.lead} /></span>
                    <ReactMarkdown source={d.content} renderers={{image: Image}}/>
                </React.Fragment>
            )
            index++;
        });
        return content;
    };

    const getPreview = () => {
        return (            
            <div>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container"><span className="cb-brand  navbar-brand">
                        <span className="navbar-brand"><img src={data.logo} className="logo-img" alt="Clifton Cobbler Logo" />
                        </span></span><button aria-controls="navbarResponsive"
                            type="button" aria-label="Toggle navigation" className="navbar-toggler collapsed"><span
                                className="navbar-toggler-icon"></span></button>
                        <div className="navbar-collapse collapse" id="navbarResponsive">
                            <div className="ml-auto navbar-nav">
                                <div className="nav-item false nav-item"><span className="nav-link">Home</span></div>
                                <div className="nav-item false nav-item"><span className="nav-link">About</span></div>
                                <div className="nav-item false nav-item"><span className="nav-link">Services</span></div>
                                <div className="nav-item false nav-item"><span className="nav-link">Contact</span></div>
                            </div>
                        </div>
                    </div>
                </nav>
                <header className="py-5 bg-full"
                    style={headerBackground}><img className="img-fluid d-block mx-auto" src={data.headerText} alt="Clifton Cobbler" style={{ maxWidth: '100%' }} />
                </header>
                <div className="container">
                    {getIntro()}
                </div>
                <section className="py-5 bg-full" style={footerBackground}>
                </section>                
            </div>
            
        );
    };

    const getHelp = () => {
        return(<React.Fragment><ArticleContent category="help" pageType={PageTypes.Images} /></React.Fragment>)
    }

    const getTabContent = () => {
        let tabs: ITabContent[] = [];
        tabs.push({
            content: getPreview(),
            tabName: "Preview"
        });
        tabs.push({
            content: getFormElements(),
            tabName: "Edit"
        });
        tabs.push({
            content: <React.Fragment><ArticleContent category="help" pageType={PageTypes.Images} /></React.Fragment>,
            tabName: "Help"
        });
        return tabs;
    }

    const getFormElements = () => {

        return (
            <section className="py-2">
                    <div className="container">
                        <form>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                <div className="input-group-prepend"><span className="input-group-text image-label" id="firstname-addon">Logo:</span></div>
                                    <input type="text" className="form-control" value={data.logo || ""} onChange={e => setData({ id: data.id, logo: e.target.value, header: data.header, footer: data.footer, headerText: data.headerText, active: data.active })} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                <div className="input-group-prepend"><span className="input-group-text image-label" id="firstname-addon">Header:</span></div>
                                    <input type="text" className="form-control" value={data.header || ""} onChange={e => setData({ id: data.id, logo: data.logo, header: e.target.value, footer: data.footer, headerText: data.headerText, active: data.active })} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                <div className="input-group-prepend"><span className="input-group-text image-label" id="firstname-addon">Header Text:</span></div>
                                    <input type="text" className="form-control" value={data.headerText || ""} onChange={e => setData({ id: data.id, logo: data.logo, header: data.header, footer: data.footer, headerText: e.target.value, active: data.active })} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                <div className="input-group-prepend"><span className="input-group-text image-label" id="firstname-addon">Footer:</span></div>
                                    <input type="text" className="form-control" value={data.footer || ""} onChange={e => setData({ id: data.id, logo: data.logo, header: data.header, footer: e.target.value, headerText: data.headerText, active: data.active })} required />
                                </div>
                            </div>                            
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <button className="btn btn-block btn-lg btn-primary" onClick={e => { e.preventDefault();data.id==="" ? handleInsert() : handleSubmission() }}>Save Changes</button>
                                    <button className="btn btn-block btn-lg btn-primary" onClick={e => { e.preventDefault();resetForm() }}>Reset</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
        )
    };


    return (
        <div className="container">
            <TabLayout content={getTabContent()} />
        </div>
    )
};