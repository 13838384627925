export const FETCH_INTRO_DATA = 'FETCH_INTRO_DATA'; 
export const UPDATE_INTRO_DATA = 'UPDATE_INTRO_DATA'; 
export const UPDATE_INTRO_ERROR = 'UPDATE_INTRO_ERROR'; 

export const FETCH_CONTENT = 'FETCH_CONTENT'; 
export const FETCH_CONTENTS = 'FETCH_CONTENTS'; 
export const UPDATE_CONTENT = 'UPDATE_CONTENT'; 
export const UPDATE_CONTENT_ERROR = 'UPDATE_CONTENT_ERROR'; 
export const PREVIEW_CONTENT = "PREVIEW_CONTENT";

export const FETCH_CONTENT_ABOUT = 'FETCH_CONTENT_ABOUT'; 
export const UPDATE_CONTENT_ABOUT = 'UPDATE_CONTENT_ABOUT'; 
export const UPDATE_CONTENT_ABOUT_ERROR = 'UPDATE_CONTENT_ABOUT_ERROR'; 

export const FETCH_CONTENT_SERVICES = 'FETCH_CONTENT_SERVICES'; 
export const UPDATE_CONTENT_SERVICES = 'UPDATE_CONTENT_SERVICES'; 
export const UPDATE_CONTENT_SERVICES_ERROR = 'UPDATE_CONTENT_SERVICES_ERROR'; 

export const LOGIN_ERROR ="LOGIN_ERROR"; 
export const LOGIN_USER = "LOGIN_USER"; 

export const REGISTER_USER ="REGISTER_USER";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";

export const UPDATE_USER ="UPDATE_USER";
export const UPDATE_ERROR = "UPDATE_ERROR";
export const RESET_UPDATE_SUCCESS = "RESET_UPDATE_SUCCESS";
export const RESET_UPDATE_USER = "RESET_UPDATE_USER";
export const RESET_USERS = "RESET_USERS";

export const GET_USERS = "GET_USERS";

export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_ERROR = "FETCH_IMAGES_ERROR";
export const FETCH_IMAGES_LIST = 'FETCH_IMAGES_LIST';
export const FETCH_IMAGES_LIST_ERROR = "FETCH_IMAGES_LIST_ERROR";
export const INSERT_IMAGES = 'INSERT_IMAGES';
export const INSERT_IMAGES_ERROR = "INSERT_IMAGES_ERROR";


export const UPDATE_IMAGES = 'UPDATE_IMAGES';
export const UPDATE_IMAGES_ERROR = "UPDATE_IMAGES_ERROR";
export const UPDATE_IMAGES_LIST = 'UPDATE_IMAGES_LIST';
export const UPDATE_IMAGES_LIST_ERROR = "UPDATE_IMAGES_LIST_ERROR";

export const FETCH_ARTICLE = 'FETCH_ARTICLE'; 
export const FETCH_ARTICLES = 'FETCH_ARTICLES'; 
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'; 
export const UPDATE_ARTICLE_ERROR = 'UPDATE_ARTICLE_ERROR'; 