import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import { Pages } from '../../interfaces';
import ArticleForm from '../../components/Articles';
import { useLocation } from 'react-router-dom'


interface IPage {    
    pageId: string;
    page: Pages
    category: string; 
}


export default function Articles() {
    const
        location = useLocation(),
        page = location.state as IPage;


    return (
        <div>
            <MainLayout page={page ? page.page : Pages.Articles}>                
                <ArticleForm category={page.category} preview={true} />
            </MainLayout>
        </div>
    );
};
