import * as React from 'react';
import * as Article from '../../Api/Article';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state';
import ReactMarkdown from 'react-markdown';
import { IArticle, PageTypes } from '../../interfaces';



interface IProps {
    pageId?: string;
    pageType: PageTypes;
    category: string;
}


export default function ArticleContent(props: IProps) {
    const
        [data, setData] = React.useState<IArticle>({ id: "", category: "", pageId: "", title: "", content: "", author: "", createDate: "", active: false }),
        dispatch = useDispatch(),
        articleData: { article: IArticle, articles: IArticle[], error?: string } = useSelector(({ articleReducer }: IAppState) => articleReducer);

    function Image(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>) {
        return <img {...props} style={{ margin: '10px' }} alt="" />
    };

    React.useEffect(() => {
        dispatch(Article.getArticleForCategoryPageId(props.category, PageTypes[props.pageType]));
    }, [])

    React.useEffect(() => {              
        setData(articleData.article);
    }, [articleData.article])
    

    return (
        <React.Fragment>
            <h3 className="pt-3">{articleData.article.title}</h3>
            <ReactMarkdown source={data.content} renderers={{ image: Image }} />
        </React.Fragment>
    )
};