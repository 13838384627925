
import { FETCH_IMAGES, FETCH_IMAGES_ERROR,FETCH_IMAGES_LIST, FETCH_IMAGES_LIST_ERROR, UPDATE_IMAGES, UPDATE_IMAGES_ERROR, INSERT_IMAGES, INSERT_IMAGES_ERROR } from '../../state/action-types';
import {IImages} from '../../interfaces';
import axios from 'axios';
import {API_URL} from '../../common'
import { IReducerAction } from '../../state/IReducerAction';

export function getActiveImages() {     
    return (dispatch: (action: IReducerAction) => any) => { 
        axios.get(`${API_URL}/Image/true`)
        .then (res =>
            dispatch({
                type: FETCH_IMAGES,
                payload: res.data as IImages
            })       
        )             
    
    };
};

export function getAllImages() {     
    return (dispatch: (action: IReducerAction) => any) => { 
        axios.get(`${API_URL}/Image`)
        .then (res =>
            dispatch({
                type: FETCH_IMAGES_LIST,
                payload: res.data as IImages[]
            })       
        )             
    
    };
};

export function updateImages(data: IImages){      
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.put(`${API_URL}/Image`, data)
        .then (res =>
            dispatch({
                type: UPDATE_IMAGES,
                payload: res.data
            })
        )
        .catch(error => {            
            dispatch({type: UPDATE_IMAGES_ERROR, payload: error})
        }) 
    };
};

export function insertImages(data: IImages){    
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.post(`${API_URL}/Image`, data)
        .then (res =>
            dispatch({
                type: INSERT_IMAGES,
                payload: data
            })
        )
        .catch(error => {            
            dispatch({type: INSERT_IMAGES_ERROR, payload: error})
        }) 
    };
};