import { FETCH_ARTICLE, UPDATE_ARTICLE, FETCH_ARTICLES, UPDATE_ARTICLE_ERROR } from '../action-types';
import { IArticle} from '../../interfaces';
import { IReducerAction } from '../IReducerAction';


export class ArticleState{
    article: IArticle;
    articles: IArticle[];    
    error?: any;

    constructor(){
        this.article= {id:"",category:"", pageId:"", title:"", content:"", author:"", createDate:"", active: false};
        this.articles = [{id:"",category:"", pageId:"", title:"", content:"", author:"", createDate:"", active: false}];        
        this.error = null;
    }
}

export default function articleReducer(state = new ArticleState(), action: IReducerAction) {
    const {type, payload} = action || {} as IReducerAction;    
    switch (type) {
        case FETCH_ARTICLE: return {...state, article: payload as IArticle }
        case FETCH_ARTICLES: return {...state, articles: payload as IArticle[] }
        case UPDATE_ARTICLE: return { ...state, article: payload }        
        case UPDATE_ARTICLE_ERROR: return { ...state, error: payload}
        default:
            return state;
    }
}

