import * as React from 'react';


export default function Footer(){

    return(
        <React.Fragment>
        <div className="clearfix"></div>
        <footer className="py-1">
            <div className="container">
            <p className="m-0 text-center">&copy; <a href="https://acheson-crow.com"  target="blank" rel="noopener noreferrer">Acheson-Crow Ltd</a> 2020 - All Rights Reserved</p>
            </div>        
        </footer>
        </React.Fragment>
    );
}