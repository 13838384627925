import * as React from 'react';
import ResizableTextarea from '../ResizableTextarea';
import './style.scss';
import { getContentsForPage, updateContent, insertContent, getContentForPageId } from '../../Api/Content'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state';
import TabLayout from '../Layout/EditPreview';
import ReactMarkdown from 'react-markdown';
import { ITabContent, IContent, PageTypes } from '../../interfaces';
import ArticleContent from '../Article';




interface IProps {
    pageId: string;
    preview: boolean;
    image?: any;
}


export default function ContentForm(props: IProps) {
    const
        [data, setData] = React.useState<IContent>({ id: "", pageId: "", content: "" }),
        dispatch = useDispatch(),
        //selector = useSelector((state: IFormState) => state);
        contentData: { content?: IContent, contents: IContent[], preview: IContent[], error?: string } = useSelector(({ contentReducer }: IAppState) => contentReducer);

    function Image(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>) {
        return <img {...props} style={{ margin: '10px' }} alt="" />
    };


    React.useEffect(() => {
        dispatch(getContentForPageId(props.pageId));
    }, [dispatch, props])

    React.useEffect(() => {        
        //contentData.content ? setData(contentData.content) : setData({ id: "", pageId: props.pageId, content: "" });
        contentData.content && setData(contentData.content)
    }, [contentData.content, props.pageId])



    const handleSubmission = async () => {
        data.id===null ? dispatch(insertContent(data)) : dispatch(updateContent(data));
    };

    const handleInsert = async () => {
        dispatch(insertContent(data))
    }

    const getHelp = () => {
        return(<React.Fragment><ArticleContent category="help" pageType={PageTypes.Content} /></React.Fragment>)
    }
    const getPreview = () => {
        return (
            <React.Fragment>
                <ReactMarkdown source={data.content} renderers={{ image: Image }} />
            </React.Fragment>
        )
    };



    const getTabContent = () => {        
        let tabs: ITabContent[] = [];
        tabs.push({
            content: props.preview ? getPreview() : getFormElements(),
            tabName: props.preview ? "Preview" : "Edit"
        });
        tabs.push({
            content: props.preview ? getFormElements() : getPreview(),
            tabName: props.preview ? "Edit" : "Preview"
        });
        tabs.push({
            content: getHelp(),
            tabName: "Help"
        });
        return tabs;
    }

    const getFormElements = () => {

            return (   
                 <section className="py-2">
                    <div className="container">
                        <form>
                            <div className="form-group">
                                <div className="input-group input-group-lg ">
                                    <ResizableTextarea className="std-textarea content-area" placeHolder="Content"
                                        value={data.content}
                                        onUpdate={e => setData({ id: data.id, pageId: props.pageId, content: e.currentTarget.value })} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <button className="btn btn-block btn-lg btn-primary" onClick={e => { e.preventDefault(); handleSubmission() }}>Save Changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
)
    };


    return (
        <div className="container">
            <TabLayout content={getTabContent()} />
        </div>
    )
};