import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import { Pages } from '../../interfaces';
import ImagesForm from '../../components/Images';



export default function Images() {

    return (
        <div>
            <MainLayout page={Pages.Images}>                
                <ImagesForm />
            </MainLayout>
        </div>
    );
};
