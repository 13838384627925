import { FETCH_IMAGES, FETCH_IMAGES_ERROR,FETCH_IMAGES_LIST, FETCH_IMAGES_LIST_ERROR, UPDATE_IMAGES, UPDATE_IMAGES_ERROR, INSERT_IMAGES, INSERT_IMAGES_ERROR, UPDATE_IMAGES_LIST, UPDATE_IMAGES_LIST_ERROR } from '../action-types';
import { IReducerAction } from '../IReducerAction';
import {IImages} from '../../interfaces';

export class ImageState{
    images?: any;
    imagesList: IImages[];
    error?: any;

    constructor(){
        this.images = null
        this.imagesList = [{id:"", logo:"", header:"", footer:"", headerText:"", active: false}];
        this.error = null;
    }
}

export default function imagesReducer(state = new ImageState(), action: IReducerAction) {
    const {type, payload} = action || {} as IReducerAction;
    switch (type) {
        case FETCH_IMAGES: return { ...state, images: payload as IImages }     
        case FETCH_IMAGES_LIST: return { ...state, imagesList: payload as IImages[] }
        case UPDATE_IMAGES: return { ...state, images: payload as IImages}
        case UPDATE_IMAGES_ERROR: return {...state, error: payload}
        case UPDATE_IMAGES_LIST: return {...state, imagesList: payload}
        case UPDATE_IMAGES_LIST_ERROR: return {...state, error: payload}        
        default: return state;
    }
}