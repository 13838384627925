import { Reducer } from 'redux';
import { createBrowserHistory } from 'history'
import {applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistCombineReducers, PersistConfig} from 'redux-persist';
import introReducer from './reducers/intro-reducer';
import contentReducer from './reducers/contentReducer';
import userReducer, {registrationReducer} from './reducers/user-reducer';
import imagesReducer from './reducers/imagesReducer';
import articleReducer from './reducers/articleReducer';
import { IAppState } from './index';
import { createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter';
import sessionStorage from 'redux-persist/lib/storage/session';
import { connectRouter, routerMiddleware } from 'connected-react-router';

const
    persistConfig: PersistConfig<IAppState> = {
        key: "root",
        storage: sessionStorage,
        transforms:[
            createWhitelistFilter("router",[]),
            createWhitelistFilter("userReducer",["currentUser"]),
            createWhitelistFilter("introReducer", ["intros"]),
            createWhitelistFilter("contentReducer", ["contents"]),
            createWhitelistFilter("imagesReducer", ["images"]),
            createWhitelistFilter("articleReducer", ["articles"]),

            createBlacklistFilter("registrationReducer", ["data", "error"])
        ]
    },
    history = createBrowserHistory({basename: ""}),
    appReducer = persistCombineReducers(persistConfig,{        
        router: connectRouter(history) as Reducer,
        introReducer,
        contentReducer,
        userReducer,
        registrationReducer,
        imagesReducer,
        articleReducer
    }),
    { __serverState } = (window || {}) as any,
    store = createStore(
        appReducer,
        __serverState || {},
        composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
    ),
    persistor = persistStore(store, undefined,() =>{});
export default store;
export {persistor, history, appReducer};

//  const middlewares = [thunk];
// // const persistConfig = {
// //     key: 'root',
// //     storage
// // }

// // const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

// // export default () => {
// //     let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
// //     let persistor = persistStore(store);
// //     return (store, persistor)
// // }

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));