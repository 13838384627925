import { REGISTER_USER, REGISTRATION_ERROR } from '../../state/action-types';
import { API_URL} from '../../common';
import axios from 'axios';
import {IRegistration, IUser} from '../../interfaces';
import { IReducerAction } from '../../state/IReducerAction';



export default function registerUser(requestData: IRegistration){
    return(dispatch: (arg0:IReducerAction) => any) => {
        let token = localStorage.getItem("token");
        let config = {headers: { Authorization: `Bearer ${token}`}}
        axios.post(`${API_URL}/User/register`, requestData, config)
        .then(res => {                 
            dispatch({
                type: REGISTER_USER,
                payload: res.data as IUser
            });  
        })
        .catch((error: any)=>{    
            dispatch({
                type: REGISTRATION_ERROR,
                payload: "There has been a problem registering your user. Please try again"
            })
        })

        
    };  
};