import { LOGIN_ERROR, LOGIN_USER, RESET_USERS } from '../../state/action-types';
import { API_URL} from '../../common';
import { ILogin } from '../../components/LoginForm';
import axios from 'axios';
import {IUser} from '../../interfaces';
import { IReducerAction } from '../../state/IReducerAction';

export function authenticateUser(requestData: ILogin){
    return(dispatch: (arg0:IReducerAction) => any) => {
        axios.post(`${API_URL}/User/authenticate`, requestData)
        .then(res => {               
            dispatch({
                type: LOGIN_USER,
                payload: res.data as IUser
            });  
            var user =  (res.data as IUser)        
            localStorage.setItem("token", user.token)
            localStorage.setItem("userName", user.username);
            localStorage.setItem("userId", user.id);
            
            return res.data;
        })
        .catch((error: any)=>{    
            dispatch({
                type: LOGIN_ERROR,
                payload: "Password or username are incorrect"
            })
        })
        
    };  
};

export function resetUsers(user:IUser | null){    
    return (dispatch:(arg:IReducerAction)=> any) =>{
        dispatch({type: RESET_USERS, payload:user});
    };
};