import * as React from 'react';
import ResizableTextarea from '../ResizableTextarea';
import './style.scss';
import * as Article from '../../Api/Article'
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state';
import TabLayout from '../Layout/EditPreview';
import ReactMarkdown from 'react-markdown';
import { ITabContent, IArticle } from '../../interfaces';




interface IProps {
    category?: string;
    pageId?: string;
    preview: boolean;
    image?: any;
}


export default function ArticleForm(props: IProps) {
    const
        [data, setData] = React.useState<IArticle[]>([{ id: "", category: "", pageId: "", title: "", content: "", author: "", createDate: "", active: false }]),
        dispatch = useDispatch(),
        //selector = useSelector((state: IFormState) => state);
        articleData: { article?: IArticle, articles: IArticle[], error?: string } = useSelector(({ articleReducer }: IAppState) => articleReducer);

    function Image(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>) {
        return <img {...props} style={{ margin: '10px' }} alt="" />
    };


    React.useEffect(() => {
        props.category && dispatch(Article.getArticlesForCategory(props.category));
    }, [dispatch, props])

    React.useEffect(() => {

        //articleData.content ? setData(articleData.content) : setData({ id: "", pageId: props.pageId, content: "" });
        articleData.articles.length > 0 && setData(articleData.articles)
    }, [articleData.articles])



    const handleSubmission = async () => {
        data.forEach(d => {
            d.id === null || d.id === "" ? dispatch(Article.insertArticle(d)) : dispatch(Article.updateArticle(d));
        })
    };


    const updateData = (article: IArticle) => {
        let newData: IArticle[] = [];
        data.forEach(d => {
            d.id === article.id ? newData.push(article) : newData.push(d)
        })

        setData(newData);
    };

    const getPreview = () => {
        let content: any = [];
        let index = 0;
        data.forEach(d => {
            content.push(
                <React.Fragment key={index}>
                    <h3>{d.title}</h3>
                    <span><small className="text-muted">{d.author}<em className="pl-2">{d.createDate}</em></small></span>
                    <hr />
                    <ReactMarkdown source={d.content} />
                    <hr />
                </React.Fragment>
            )
            index++;
        });
        return content;
    };



    const getTabContent = () => {
        let tabs: ITabContent[] = [];
        tabs.push({
            content: props.preview ? getPreview() : getFormElements(),
            tabName: props.preview ? "Preview" : "Edit"
        });
        tabs.push({
            content: props.preview ? getFormElements() : getPreview(),
            tabName: props.preview ? "Edit" : "Preview"
        })
        return tabs;
    }

    const getFormElements = () => {
        let formElements: any = [];
        data.forEach(d => {
            formElements.push(
                <section className="py-2" key={d.id}>
                    <div className="container">
                        <form>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend"><span className="input-group-text form-label" >Title:</span></div>
                                    <input type="text" className="form-control" value={d.title || ""} onChange={e => updateData({ id: d.id, category: d.category, pageId: d.pageId, title: e.currentTarget.value, content: d.content, author: d.author, createDate: d.createDate, active: d.active })} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend"><span className="input-group-text form-label" >Category:</span></div>
                                    <input type="text" className="form-control" value={d.category || ""} onChange={e => updateData({ id: d.id, category: e.currentTarget.value, pageId: d.pageId, title: d.title, content: d.content, author: d.author, createDate: d.createDate, active: d.active })} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend"><span className="input-group-text form-label" >Page:</span></div>
                                    <input type="text" className="form-control" value={d.pageId || ""} onChange={e => updateData({ id: d.id, category: d.category, pageId: e.currentTarget.value, title: d.title, content: d.content, author: d.author, createDate: d.createDate, active: d.active })} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <div className="input-group-prepend"><span className="input-group-text form-label" >Author:</span></div>
                                    <input type="text" className="form-control" value={d.author || ""} onChange={e => updateData({ id: d.id, category: d.category, pageId: d.pageId, title: d.title, content: d.content, author: e.currentTarget.value, createDate: d.createDate, active: d.active })} required />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group input-group-lg ">
                                    <ResizableTextarea className="std-textarea content-area" placeHolder="Content"
                                        value={d.content}
                                        onUpdate={e => updateData({ id: d.id, category: d.category, pageId: d.pageId, title: d.title, content: e.currentTarget.value, author: d.author, createDate: d.createDate, active: d.active })} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-check">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                        <input type="checkbox"  checked={d.active} onChange={e => updateData({ id: d.id, category: d.category, pageId: d.pageId, title: d.title, content: d.content, author: d.author, createDate: d.createDate, active: e.target.checked })} required />
                                        </div>
                                        <span className="pl-2 input-group-lg">Active</span>
                                    </div>
                                    
                                </div>
                            </div>                            
                            <div className="form-group">
                                <div className="input-group input-group-lg">
                                    <button className="btn btn-block btn-lg btn-primary" onClick={e => { e.preventDefault(); handleSubmission() }}>Save Changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            )
        });
        return formElements;
    };


    return (
        <div className="container">
            <TabLayout content={getTabContent()} />
        </div>
    )
};