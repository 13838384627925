export interface IUser{
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    token: string;    
}

export interface IUpdateUser {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    password: string;
    token: string;      
}

export interface IRegistration {
    firstName: string;
    lastName: string;
    username: string;
    password: string;
}

export interface ITabContent {
    content: any,
    tabName: string    
}

export enum Pages{
    Home,
    About,
    Services,
    Contact,
    Landing,
    Login,
    Register,
    Admin,
    UpdateUser,
    Users,
    Intro,
    LandingEdit,
    Images,
    Help,
    Articles

}

export enum PageTypes {
    Intro,
    Help,
    Content,
    Blog,
    Images
}

export interface IImages{
    id: string;
    logo: string;
    header: string;
    footer: string;
    headerText: string;
    active: boolean;
  }

  export interface IIntro {
    id: string;    
    heading: string;
    lead: string;
    content: string
};


export interface IContent {
    id: string;
    pageId: string;
    content: string;
};

export interface IArticle {
    id: string;
    category: string;
    pageId: string;
    title: string;
    content: string;
    author: string;
    createDate: string;
    active: boolean;
}
