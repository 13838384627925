import {
    FETCH_ARTICLE, UPDATE_ARTICLE, UPDATE_ARTICLE_ERROR, FETCH_ARTICLES
} from '../../state/action-types';
import { IArticle} from '../../interfaces';
import axios from 'axios';
import { API_URL} from '../../common';
import { IReducerAction } from '../../state/IReducerAction';



export function getAllArticles() {    
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/article`)
        .then (res =>
            dispatch({
                type: FETCH_ARTICLES,
                payload: res.data
            })       
        )             
    
    };
};

export function getArticlesForCategory(category: string) {   
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/article/category/${category}`)
        .then (res =>
            dispatch({
                type: FETCH_ARTICLES,
                payload: res.data as IArticle[]
            })       
        )             
    
    };
};

export function getArticlesForCategoryActive(category: string, active: boolean) {   
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/article/category/${category}/active/${active}`)
        .then (res =>
            dispatch({
                type: FETCH_ARTICLES,
                payload: res.data as IArticle[]
            })       
        )             
    
    };
};


export function getArticleForPageId(pageId: string) {       
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/article/page/${pageId}`)
        .then (res =>
            dispatch({
                type: FETCH_ARTICLE,
                payload: res.data as IArticle
            })                  
        )             
    
    };
};

export function getArticleForCategoryPageId(category: string, pageId: string) {       
    //console.log(`${API_URL}/article/category/${category}/pageId/${pageId}`);
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/article/category/${category}/pageId/${pageId}`)
        .then (res =>
            dispatch({
                type: FETCH_ARTICLE,
                payload: res.data as IArticle
            })                  
        )             
    
    };
};

export function getArticleHavingId(id: string) {       
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/article/${id}`)
        .then (res =>
            dispatch({
                type: FETCH_ARTICLE,
                payload: res.data as IArticle
            })                  
        )             
    
    };
};

export function updateArticle(data: IArticle){    
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.put(`${API_URL}/article`, data)
        .then (res =>
            dispatch({
                type: UPDATE_ARTICLE,
                payload: data
            })
        )
        .catch(error => {            
            dispatch({type: UPDATE_ARTICLE_ERROR, payload: error})
        }) 
    };
};

export function insertArticle(data: IArticle){    
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.post(`${API_URL}/article`, data)
        .then (res =>
            dispatch({
                type: UPDATE_ARTICLE,
                payload: res.data
            })
        )
        .catch(error => {            
            dispatch({type: UPDATE_ARTICLE_ERROR, payload: error})
        }) 
    };
};




