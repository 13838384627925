import { GET_USERS, RESET_USERS, LOGIN_USER, LOGIN_ERROR, REGISTER_USER, REGISTRATION_ERROR,UPDATE_USER, UPDATE_ERROR, RESET_UPDATE_SUCCESS, RESET_UPDATE_USER } from '../action-types';
import { IUser, IRegistration } from '../../interfaces';
import { IReducerAction } from '../IReducerAction';

export class UserState {
    currentUser?: IUser | null;
    error?: any;
    updateSuccess: boolean;
    users?: IUser[] | null;
    updateUser: IUser | null;

    constructor(){
        this.currentUser = null;
        this.error = null;
        this.updateSuccess = false;
        this.users = null;
        this.updateUser = null;
    }
}

export default function userReducer (state = new UserState(), action: IReducerAction){
    const {type, payload} = action || {} as IReducerAction;

    switch(type) {
        case LOGIN_USER: return { ...state, currentUser: payload }
        case LOGIN_ERROR: return { ...state, error: payload }
        case UPDATE_USER: return {
             ...state, 
             currentUser: state.currentUser?.id === (payload as IUser).id ? payload as IUser : state.currentUser,
             updateUser: payload as IUser, 
             updateSuccess: true }
        case UPDATE_ERROR: return { ...state, error: payload, updateSuccess: false } 
        case RESET_UPDATE_SUCCESS: return { ...state,currentUser: payload, updateSuccess: false } 
        case RESET_UPDATE_USER: return {...state, currentUser: state.currentUser, updateUser: payload, updateSuccess: false}
        case GET_USERS: return { ...state, currentUser: state.currentUser, users: payload }
        case RESET_USERS: return { ...state, currentUser: null, users: null, updateUser: null}
        default:
            return state;
    }
}


export class RegistrationState{    
    success: string;
    user?: IUser | null;
    error?: any;

    constructor(){        
        this.success = "";
        this.error = null;
        this.user = null
    }

}

export function registrationReducer(state = new RegistrationState(), action: IReducerAction){
    const {type, payload} = action || {} as IReducerAction;
    switch(type) {
        case REGISTER_USER: return { ...state,success:"success", user: payload }
        case REGISTRATION_ERROR: return { ...state, error: action }
        default:
            return state;
    }
}
