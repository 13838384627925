import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../scss/style.scss';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.scss';
import {Pages, IImages} from '../../interfaces';
import { useSelector } from 'react-redux';
import { IAppState } from '../../state';



interface IProps{
    active: Pages;
}

export default function NavigationBar(props: IProps){    
  
  const     
    imageData: { images?: IImages, error?: string } = useSelector(({ imagesReducer }: IAppState) => imagesReducer);

    return (
      <Navbar bg="light" expand="lg" fixed="top">
        <div className="container">
          <Navbar.Brand className="cb-brand">                
              <a href="https://cliftoncobbler.com" target="_blank" rel="noopener noreferrer" className="navbar-brand">
                <img src={imageData.images?.logo} className="logo-img" alt="Clifton Cobbler Logo" />
              </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ml-auto">
              <Nav.Item className={`nav-item ${props.active===Pages.Landing && "active"}`} >
                <Link className="nav-link" to="/" >Home</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Images && "active"}`} >
                <Link className="nav-link" to={{
                  pathname:'/images',
                  state:{
                    pageId:'images',
                    page: Pages.Images
                  }}} >Images</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Intro && "active"}`} >
                <Link className="nav-link" to="/intro" >Front</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.About && "active"}`} >
                <Link className="nav-link" to={{
                  pathname:'/about',
                  state:{
                    pageId:'about',
                    page: Pages.About
                  }
                }}>About</Link>
              </Nav.Item>              
              <Nav.Item className={`nav-item ${props.active===Pages.Services && "active"}`} >
              <Link className="nav-link" to={{
                  pathname:'/services',
                  state:{
                    pageId:'services',
                    page: Pages.Services
                  }
                }}>Services</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Admin && "active"}`} >
                <Link className="nav-link" to="/admin" >Admin</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Contact && "active"}`} >
                <a className="nav-link" href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noopener noreferrer">Markdown</a>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active===Pages.Help && "active"}`} >
                <Link className="nav-link" to={{
                  pathname:'/help',
                  state:{
                    pageId:'help',
                    page: Pages.Help,
                    category: "help"
                  }}} >Help</Link>
              </Nav.Item>    
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>     
    );
};
