import {
    FETCH_CONTENT, UPDATE_CONTENT, UPDATE_CONTENT_ERROR, PREVIEW_CONTENT, FETCH_CONTENTS
} from '../../state/action-types';
import { IContent } from '../../interfaces';
import axios from 'axios';
import { API_URL} from '../../common';
import { IReducerAction } from '../../state/IReducerAction';



export function getContentForAllPages() {    
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/Content`)
        .then (res =>
            dispatch({
                type: FETCH_CONTENT,
                payload: res.data
            })       
        )             
    
    };
};

export function getContentsForPage(pageId: string) {   
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/contents/${pageId}`)
        .then (res =>
            dispatch({
                type: FETCH_CONTENTS,
                payload: res.data as IContent[]
            })       
        )             
    
    };
};

export function getContentForPageId(pageId: string) {       
    return (dispatch: (arg0: IReducerAction) => any) => { 
        axios.get(`${API_URL}/content/${pageId}`)
        .then (res =>
            dispatch({
                type: FETCH_CONTENT,
                payload: res.data as IContent
            })                  
        )             
    
    };
};

export function updateContent(data: IContent){    
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.put(`${API_URL}/Content`, data)
        .then (res =>
            dispatch({
                type: UPDATE_CONTENT,
                payload: data
            })
        )
        .catch(error => {            
            dispatch({type: UPDATE_CONTENT_ERROR, payload: error})
        }) 
    };
};

export function insertContent(data: IContent){    
    return(dispatch:(arg0:IReducerAction) => any) =>{
        axios.post(`${API_URL}/Content`, data)
        .then (res =>
            dispatch({
                type: UPDATE_CONTENT,
                payload: res.data
            })
        )
        .catch(error => {            
            dispatch({type: UPDATE_CONTENT_ERROR, payload: error})
        }) 
    };
};

export function previewContent(content: IContent){
    return(dispatch:(action: IReducerAction)=> any) =>{
        dispatch({
            type: PREVIEW_CONTENT,
            payload: [content]
        })
    };
};


