import { FETCH_INTRO_DATA, UPDATE_INTRO_DATA } from '../action-types';
import { IIntro} from '../../interfaces';
import { IReducerAction } from '../IReducerAction';


export class IntroState{
    intro?: any;
    intros: IIntro[];
    error?: any;

    constructor(){
        this.intro = null
        this.intros = [{id:"", heading:"", lead:"", content:""}];
        this.error = null;
    }
}

export default function introReducer(state = new IntroState(), action: IReducerAction) {
    const {type, payload} = action || {} as IReducerAction;
    switch (type) {
        case FETCH_INTRO_DATA:               
            return {
                ...state,
                intros: payload as IIntro[]
            }
        case UPDATE_INTRO_DATA:
            return {
                ...state,
                error: payload
            }
        default:
            return state;
    }
}



